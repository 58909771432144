import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide31/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide31/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide31/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide31/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide31/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Retención de clientes: Cómo mejorar este KPI en 5 pasos
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Ofrecer un producto excelente suele no ser suficiente para atraer y
      retener clientes nuevos y existentes. Otros factores, como el soporte al
      cliente, el diseño de la experiencia de usuario y la competencia, pueden
      influir en tu capacidad para tener una alta tasa de retención.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    La retención es una métrica crucial que influye en el crecimiento y la
    rentabilidad de una empresa. Básicamente, tu tasa de retención muestra
    cuántos clientes siguen utilizando tu producto o servicio durante un período
    determinado.
    <br />
    <br />
    Sin embargo, las tasas de retención pueden variar ampliamente según las
    diferentes industrias. No hay una respuesta única sobre cuál es una buena
    tasa de retención. Cada empresa debe comprender los estándares de su sector.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Qué significa una buena retención del cliente.",
      },
      {
        key: 2,
        text: "Cómo calcular la retención del cliente.",
      },
      {
        key: 3,
        text: "Fórmula para calcular la retención.",
      },
      {
        key: 4,
        text: "Estrategias para mejorar la retención de clientes.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
